.map-control-zoom-out-button {
  background-color: #1f96f3;
  border: none;
  border-radius: 3px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.3);
  color: rgb(25, 25, 25);
  cursor: pointer;
  font-family: Roboto, Arial, sans-serif;
  margin: 10px 10px;
  height: 40px;
  width: 40px;
  padding: 0px 5px;
  text-align: center;
}

.map-control-zoom-out-button svg {
  height: 40px;
}
